@import "src/_scss/w98/var/colors";
@import "src/_scss/w98/functions/box-shadows";
@import "src/_scss/w98/var/var";
@import "src/_scss/w98/var/uris";
@import "src/_scss/w98/_menu";

@mixin window-basic {
  position: relative;
  background-color: $grey;
  padding: 3px;
  box-shadow: dualShadow($grey, $black), dualShadow(#ffffff, $darkgrey, 2);
  display: inline-block;
}

@mixin window-drag {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: dualShadow($darkgrey, $darkgrey, 3);
  > *,
  &:after {
    filter: opacity(0.1%);
  }
}

@mixin window-maximized {
  width: 100%;
  height: 100%;
}

@mixin window-heading {
  display: flex;
  background: linear-gradient(to right, $blue, #126fc2);
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1px;
  padding: 0px 1px 0px 3px;
  align-items: center;
  letter-spacing: 1px;

  button {
    padding: 0px;
    min-width: initial;
    width: 16px;
    height: 14px;
    margin-left: 1px;
    image-rendering: pixelated;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: 1px 1px;

    &:focus,
    &.clicked {
      outline: none;
      border: none;
    }

    &:active:focus,
    &.clicked {
      padding: 2px 8px 1px 4px;
      background-position: 2px 2px;
    }
  }
}

@mixin window-icon {
  padding: 8px;
  display: flex;
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 4px;
}

@mixin window-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  min-width: 0px;
  user-select: none;
}

@mixin window-menu($classname: "div") {
  display: flex;
  padding: 0px;
  font-size: 1rem;
  position: relative;
  overflow-y: visible;
  z-index: 20;

  > div {
    position: relative;

    > button {
      padding: 0px 4px;
      outline: none;
      border: none;
      user-select: none;
      color: $black;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0);
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      padding: 3px 6px;
      text-transform: capitalize;

      + div,
      + #{$classname} {
        z-index: 20;
        visibility: hidden;
        position: absolute;
        max-height: 0px;
        top: 100%;
        left: 0px;
        @media (min-height: 720px) and (min-width: 960px) {
          transition: max-height linear 750ms;
        }
      }

      &:hover {
        box-shadow: dualShadow(#ffffff, $darkgrey);
      }

      &:active,
      &:focus,
      &:active:focus,
      &.active,
      &.clicked {
        box-shadow: dualShadow($darkgrey, #ffffff);
        padding: 4px 5px 2px 7px;

        + div,
        + #{$classname} {
          visibility: visible;
          max-height: 480px;
        }
      }
    }
  }
}

@mixin window-settings-section() {
  position: relative;
  border: 1px solid #ffffff;
  outline: 1px solid $darkgrey;
  padding: 5px;
  margin: 16px 8px 8px;

  &__title {
    position: absolute;
    top: -10px;
    padding: 2px 4px;
    background-color: $grey;
  }
}

@mixin window-alert() {
  display: inline-flex;
  flex-direction: column;
  max-width: 250px;
  &__message {
    display: flex;
    align-items: center;
    user-select: none;
    &.has-icon {
      background-size: 28px 28px;
      background-repeat: no-repeat;
      background-position: 6px 6px;
      padding: 6px 4px 8px 40px;
    }
    min-height: 28px;
    padding: 10px 2px 6px;
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    .btn {
      margin: 0px 4px 8px;
    }
  }
}
