.smiley {
  background-repeat: no-repeat;
  width: 19px;
  height: 19px;
  display: inline-block;
  vertical-align: sub;
  overflow: hidden;
  color: transparent;
  text-indent: -9999px;
  position: relative; // sit on top of background color
  background-color: white !important;

  // [8, 10, 12, 14, 16, 18, 24, 38];

  $smileys: "smile", "frown", "wink", "tongue", "oface", "kiss", "angry",
    "glasses", "money", "foot", "embarassed", "halo", "slant", "cry", "silent",
    "grin";

  @each $smiley in $smileys {
    &--#{$smiley} {
      background-image: url("../images/smileys/#{$smiley}_m.png");
      box-sizing: content-box;
      background-position: center;
      // margin: 2px 0;
      // padding: 2px;
    }

    &--#{$smiley}[style*="font-size: 8px"],
    &--#{$smiley}[style*="font-size: 10px"],
    [style*="font-size: 8px"] > &--#{$smiley},
    [style*="font-size: 10px"] > &--#{$smiley} {
      width: 12px;
      height: 12px;
      background-image: url("../images/smileys/#{$smiley}_s.png");
      // padding: 2px;
    }

    &--#{$smiley}[style*="font-size: 16px"],
    &--#{$smiley}[style*="font-size: 18px"],
    [style*="font-size: 16px"] > &--#{$smiley},
    [style*="font-size: 18px"] > &--#{$smiley} {
      width: 25px;
      height: 25px;
      background-image: url("../images/smileys/#{$smiley}_l.png");
      // padding: 2px 4px;
    }

    &--#{$smiley}[style*="font-size: 24px"],
    &--#{$smiley}[style*="font-size: 38px"],
    [style*="font-size: 24px"] > &--#{$smiley},
    [style*="font-size: 38px"] > &--#{$smiley} {
      width: 36px;
      height: 36px;
      background-image: url("../images/smileys/#{$smiley}_xl.png");
      // padding: 4px 8px;
    }
  }
}
