@import "./var/colors";
@import "./functions/box-shadows";
@import "./var/uris";

$start: "data:image/gif;base64,R0lGODlhNAATAPIFAAAAAAAA/wD/AP//AP8AAAAAAAAAAAAAACH5BAUAAAUALAAAAAA0ABMAAAOMWLrc/jDKSau9OF/Agf5RJ3ogRi4dwQkdI5aPJ6cqILAnp+j7KfGxEaF2AwJdvlBSQWhwaivjstCiVltVRnMLHR1fqKWOd2wMh50AZ5BFhsNYUlkRcAICasCAPZX2/mRTdYN5eGt8b1aJgYqND3iGdyKIcEmBcYkudl5zMBAyVm1tnhMjn6SoqaqrDwkAOw==";

@mixin button {
  border: 0px solid transparent;
  background-color: $grey;
  position: relative;
  user-select: none;

  &:disabled,
  &.disabled {
    pointer-events: none;
  }

  &:active,
  &:focus,
  &:active:focus,
  &.active,
  &.clicked {
    outline: none;
    color: inherit;
  }

  &:before {
    position: absolute;
    display: block;
    top: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
  }
}

@mixin button-form {
  min-width: 48px;
  outline-width: 1px;
  outline-offset: -5px;
  padding: 5px 1px;
  box-shadow: dualShadow(#ffffff, $black), dualShadow($grey, $darkgrey, 2);
  font: inherit;

  &:focus {
    outline: $black;
    outline-style: dotted;
    outline-width: 1px;
    box-shadow: dualShadow($black, $black), dualShadow(#ffffff, $black, 2);
  }

  &:disabled {
    color: revert;
  }

  &:active:focus,
  &:active,
  &.active,
  &.clicked {
    padding: 6px 0px 4px 2px;
    box-shadow: dualShadow($black, $black), dualShadow($darkgrey, $darkgrey, 2);
  }
}

@mixin button-large-icon {
  padding: 2px;
  width: 48px;
  min-width: 48px;
  height: 38px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  &__text {
    margin-top: auto;
  }
  .ButtonIconLarge__icon {
    flex-grow: 1;
    width: 20px;
    height: 20px;
    margin: 1px auto 2px;
  }
  img {
    max-width: 20px;
    max-height: 20px;
    display: block;
    filter: grayscale(1);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }
  &:disabled,
  &.disabled {
    color: $darkgrey;
    &:hover {
      img {
        filter: grayscale(1);
      }
      box-shadow: none;
    }
  }
  &:hover {
    img {
      filter: grayscale(0);
    }
    box-shadow: dualShadow(#ffffff, $black);
  }
  &:active:focus {
    box-shadow: dualShadow($black, #ffffff);
    padding: 3px 1px 1px 3px;
  }
}

@mixin button-small-icon {
  height: 22px;
  width: 22px;
  padding: 0px;

  img {
    margin: 3px;
    max-height: 16px;
    max-width: 16px;
  }

  &:hover,
  &--hasBorder {
    box-shadow: dualShadow(#ffffff, $darkgrey);
  }
  &:hover:focus:active,
  &:hover:active,
  &.active,
  &.clicked {
    box-shadow: dualShadow($darkgrey, #ffffff);
    img {
      margin: 4px 2px 2px 4px;
    }
  }
  &.btn--disabled {
    img {
      filter: grayscale(1);
    }
  }
}

@mixin button-nav {
  padding: 0px;
  min-width: initial;
  width: 16px;
  height: 14px;
  margin-left: 1px;
  margin-top: 1px;
  margin-bottom: 2px;
  image-rendering: pixelated;
  box-shadow: dualShadow(#ffffff, $black), dualShadow($grey, $darkgrey, 2);

  img {
    height: 14px;
    width: 14px;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &:active:focus,
  &.clicked {
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 4px;
    padding-right: 8px;
    box-shadow: dualShadow($black, #ffffff), dualShadow($darkgrey, $grey, 2);
  }
  &.window__close,
  &.Window__close {
    margin-left: 2px;
  }
}

@mixin button-program {
  flex: 1;
  margin: 0px 1px;
  height: 22px;
  max-width: 140px;
  min-width: 40px;
  display: inline-block;
  width: 100%;
  padding-top: 1px;
  padding-left: 22px;
  padding-right: 3px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-repeat: no-repeat;
  background-position: 4px 3px;
  box-shadow: dualShadow(#ffffff, $black), dualShadow($grey, $darkgrey, 2);

  &:active:focus,
  &.btn--active,
  &.clicked {
    background-position: 5px 5px;
    box-shadow: dualShadow($black, #ffffff), dualShadow($darkgrey, $grey, 2);
    padding-top: 3px;
    padding-left: 23px;
    padding-right: 2px;

    &:before {
      content: "";
      background-size: 2px;
      z-index: -1;
      box-shadow: none;
    }
  }

  &.btn--active {
    background-color: transparent;
    font-weight: bold;

    &:before {
      content: "";
      background-color: #ffffff;
      background-image: url($grey-checkered);
    }
  }
}

@mixin button-start {
  height: 22px;
  display: flex;
  align-content: center;
  width: 54px;
  padding-right: 6px;
  background-image: url($start);
  background-size: auto 18px;
  background-repeat: no-repeat;
  background-position: 2px 1px;
  box-shadow: dualShadow(#ffffff, $black), dualShadow($grey, $darkgrey, 2);

  &__text {
    font-size: 1rem;
    font-weight: bold;
  }

  &.active,
  &.clicked {
    box-shadow: dualShadow($black, #ffffff), shadow($black, 0, 1),
      dualShadow($darkgrey, $grey, 2), 0px -1px 0px $black;
    background-position: 3px 2px;
    outline: 1px dotted $black;
    outline-offset: -4px;
  }
}
