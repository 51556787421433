$blue: #0000a2;
$grey: #bbc3c4;
$black:  #0c0c0c;
$darkgrey: #808088;

// stripe
$red: #fb0006;
$yellow: #ffff09;
$lightgreen: #22ff04;
$lightblue: #21ffff;

$folderYellow: #ffff09;
$folderInnerBorder: #a2a44f;
$folderOuterBorder: #000000;
