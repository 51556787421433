@import "./var/colors";
@import "./functions/box-shadows";

$w98-text: "data:image/gif;base64,R0lGODlhDgBkALMAAAAAAP///wIAsZKSmZKTmpGSmZKTmcjOz8fNzsfOz8fOzv///wAAAAAAAAAAAAAAACH5BAEAAAsALAAAAAAOAGQAAAT/cMk5SUo06CO179wSGEowgEOQBcRUEuqkUaIRd/cCwyvFzyJNS3JQ2Tyt0QLBklgwEqZGQasShr4DQhuilDxgRCWAINgIAkIxFoB2DDJWbmGb2Oq0nJx2dqoCXUEuKl8GMCZRSjpgWAdYEydVkhMJQlVkQR8UTFRgQDhiHkc9QRyfRwRSV5+ZH1KbnodzjEGPCAYFcBIJj5mOk61IkgZSnpKVxpSeYCuegTjCw8Uev1bLPkfXccuY29SSGgmRky2p4b2Jnm5+3LrQ3CsY5Wuk9ZlwcJrv2uzLvWthJgH0cWVAKkMGBjhKws1YQ4cPP1wxUETclUPuBOXRY4mOvmDJafaFFMmKwoEDCspIgnGSC0pYDZvB88YvE7Bd3YABrBlRJs+HN73MiPgq4heQYJAhlYiOhqyUwLhVo7TTWcYlyEZOmAbEYM+I4hape4b0Cg0tDXlVyapVR9UY5h7KaogAg9R1c82ubEohAgA7";

@mixin task-bar($classname: "task-bar") {
  position: fixed;
  background-color: $grey;
  bottom: 0px;
  left: 0px;
  width: 100%;
  max-width: 100%;
  z-index: 10;
  box-shadow: 0px -1px 0px #ffffff;
  padding: 2px 0px;
  display: flex;

  > div,
  > button {
    position: relative;
    height: 22px;
    margin: 0px 2px;
  }

  > div:not(:last-child) {
    padding: 0px 6px;
    &:first-child {
      padding: 0px 3px 0px 0px;
    }
    &:after {
      position: absolute;
      top: 1px;
      right: 0px;
      height: calc(100% - 2px);
      width: 1px;
      background-color: $darkgrey;
      content: "";
      box-shadow: 1px 0px 0px #ffffff;
    }
    &:before {
      position: absolute;
      top: 3px;
      right: -6px;
      height: calc(100% - 6px);
      width: 3px;
      background-color: $grey;
      content: "";
      box-shadow: dualShadow(#ffffff, $darkgrey);
    }
  }

  &__programs {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-wrap: nowrap;
    margin-right: 4px;
    min-width: 42px;

    &:before {
      display: none;
    }
  }
  &__start {
    position: relative;
    > button {
      + div {
        @media (min-height: 720px) and (min-width: 960px) {
          transition: max-height linear 200ms;
        }
        position: fixed;
        bottom: 25px;
        left: 2px;
        visibility: hidden;
        max-height: 0px;
        padding-left: 22px;
        > .divider:empty,
        > div:empty {
          margin-left: 24px;
          width: calc(100% - 26px);
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 3px;
          top: 3px;
          height: calc(100% - 6px);
          width: 20px;
          background: $blue;
          background: linear-gradient($blue, #126fc2);
          background: url($w98-text) no-repeat bottom 3px center,
            linear-gradient($blue, #126fc2);
        }
        > div {
          display: flex;
          align-items: center;
          margin-left: 20px;
          > button {
            height: 32px;
            padding-left: 32px;
            background-size: 22px;
            background-position: 4px center;
          }
        }
      }

      &.active,
      &.clicked {
        background-position: 3px 2px;
        outline: 1px dotted $black;
        outline-offset: -4px;

        > div {
          visibility: visible;
          max-height: 100vh;
          padding: 3px;
          div {
            display: flex;
          }
        }
      }
    }

    &.active {
      > div {
        visibility: visible;
        max-height: 100vh;
        padding: 3px;
        div {
          display: flex;
        }
      }
    }
  }

  &__notifications {
    background-color: $grey;
    display: flex;
    flex: none;
    margin-left: auto;
    align-items: center;
    height: 22px;
    padding: 0px 8px 0px 4px;
    box-shadow: dualShadow($darkgrey, #ffffff);

    &__time {
      margin-left: 4px;
    }

    &__notifier {
      height: 16px;
      width: 16px;
      background-color: $grey;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
      &:active,
      &:focus,
      &:active:focus,
      &.active,
      &.clicked {
        outline: none;
        border: none;
      }
    }
  }
}
