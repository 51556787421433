@import "src/_scss/w98/var/colors";
@import "src/_scss/w98/mixins/box-shadows";
@import "src/_scss/w98/var/var";
@import "src/_scss/w98/var/uris";

@mixin input-text {
  position: relative;
  padding: 3px 3px 6px;
  font-size: $base-font-size;
  border: none;
  @include shadow-input;

  &:active,
  &:focus,
  &:active:focus,
  &.clicked {
    outline: none;
  }
  &:disabled,
  &.disabled {
    background-color: $grey;
  }
}

@mixin select-multiple-simple {
  position: relative;
  border: none;
  background-color: #ffffff;
  border-radius: 0px;
  outline: none;
  padding: 2px;
  @include shadow-input;

  &:active,
  &:focus,
  &:active:focus,
  &.active,
  &.clicked {
    outline: none;
  }
  option {
    &:active,
    &:focus,
    &:checked,
    &.checked {
      outline: 1px dotted #ffffff;
      outline-offset: -1px;
      background-color: $blue;
      color: #ffffff;
    }
  }
}

@mixin select-hover {
  outline: 1px dotted #ffffff;
  outline-offset: -1px;
  background-color: $blue;
  color: #ffffff;
}

@mixin toggle {
  opacity: 0;
  display: none;
  cursor: pointer;
  + label {
    position: relative;
    padding: 1px 0px;
    padding-left: 16px;
    > span,
    > div {
      display: inline-block;
      border: 1px solid rgba(0, 0, 0, 0);
    }
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 1px;
      width: 20px;
      height: 12px;
      background-repeat: no-repeat;
    }
  }
  &:checked {
    + label {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    &:active,
    &:focus,
    &:active:focus,
    &.active,
    &.clicked {
      + label {
        > span,
        > div {
          border: 1px dotted $black;
        }
      }
    }
  }
  &:disabled,
  &.disabled {
    + label {
      color: $darkgrey;
    }
  }
}

@mixin checkbox {
  @include toggle();
  + label:before {
    width: 13px;
    height: 13px;
    background-color: #ffffff;
    @include shadow-input;
  }
  &:checked + label:before {
    background-image: url($menu-checked);
    background-position: center;
    background-size: 8px;
  }
  &:disabled,
  &.disabled {
    + label:before {
      background-color: $grey;
    }
    &:checked + label:before {
      background-image: url($menu-checked-disabled);
    }
  }
}

@mixin radio {
  @include toggle();
  + label:before {
    background-image: url($radio-off);
  }
  &:checked {
    + label {
      &:before {
        background-image: url($radio-on);
      }
    }
  }
  &:disabled,
  &.disabled {
    + label:before {
      background-image: url($radio-off-disabled);
    }
    &:checked {
      + label {
        &:before {
          background-image: url($radio-on-disabled);
        }
      }
    }
  }
}
