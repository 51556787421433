@import "../../_scss/w98/_menu";

.StandardMenu {
  @include standard-menu(".StandardMenu");

  @include standard-menu-css(".StandardMenu");
}

.StandardMenuItem {
  &--empty {
    .StandardMenuItem__button {
      text-shadow: 1px 1px #ffffff;
      text-align: center;
    }
  }
}
